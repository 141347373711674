import React from 'react'
import NavBar from './NavBar'
import CopyRight from './CopyRight'
import AnnouncementBar from './Annoucement'
import { Outlet } from 'react-router-dom'

const messages = [
  "Sign up today and receive an incredible 200% Bonus on your first deposit!",
];

const Layout = () => {
  return (
    <React.Fragment>
        <AnnouncementBar messages={messages}/>
        <NavBar />
        <Outlet />
        <CopyRight />
    </React.Fragment>
  )
}

export default Layout
