import React from 'react'
import "../components/MilkyWay.css";
import { Link } from 'react-router-dom';


const NotFound = () => {
  return (
    <React.Fragment>
        <div className="background-image" >
            <div className="hero-wrapper">
            <div className="banner-container-three" style={{ marginTop : '6rem'}}>
                <div className="now-play">404 Page Not Found</div>
                <div className="signup-txt">The page you are trying to visit does not exist.</div>
                <div className="signup-txt"><Link to='/'><button className="rounded-pill nav-button mx-3">Go back to Homepage</button></Link></div>
            </div>     
            </div>

        </div>
    </React.Fragment>
  )
}

export default NotFound
