import React, { useState } from "react";
import { Container } from 'reactstrap';
import "./FaqSection.css";

const FaqSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: "What advantage do Milky Way Casino Games users get?",
            answer: "Significant advantages of the Milky Way Casino Games app are that it is free, offers a variety of games, is mobile, has a system of rewards and bonuses, and is functional."
        },
        {
            question: "What must I do to begin with Milky Way Casino Games?",
            answer: "The Milky Way Casino Games can be installed from the Google Play or App Store for iOS users. However, you can sign up for a free account after downloading the application and start playing."
        },
        {
            question: "Is there a bonus when you sign up or register?",
            answer: "Yes, Milky Way Casino Games gives a bonus of 200% on the first deposit."
        },
        {
            question: "Can I play Milky Way casino games on my mobile phone or tablet?",
            answer: "Yes, the games are fully compatible with mobile devices, allowing everyone to access the Milky Way wherever they are."
        },
        {
            question: "Are Milky Way Casino Games fair?",
            answer: "Indeed, Milky Way Casino Games are very fair."
        }
    ];

    const toggleAnswer = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <React.Fragment>
            <div className="description-bg">
                <Container>

                    <h3 className="the-milky-way">FAQs (Frequently Asked Questions)</h3>
                        {faqs.map((faq, index) => (
                            <div className="faq-container" key={index} onClick={() => toggleAnswer(index)}>
                                <div className="question">
                                    <span>{faq.question}</span>
                                    <span className={`arrow ${activeIndex === index ? "expanded" : ""}`}>{`>`}</span>
                                </div>
                                {activeIndex === index && (
                                    <div className="answer">
                                        {faq.answer}
                                    </div>
                                )}
                            </div>
                        ))}

                </Container>
            </div>
        </React.Fragment>
    );
};

export default FaqSection;
