const cardData = [
  { image: "game-01.png", name: "4thofjuly" },
  { image: "game-02.png", name: "777reels" },
  { image: "game-03.png", name: "777fruity" },
  { image: "game-04.png", name: "888fortune gold" },
  { image: "game-05.png", name: "plus air strike" },
  { image: "game-06.png", name: "alice fairy" },
  { image: "game-07.png", name: "aladdin's fortune" },
  { image: "game-08.png", name: "birdking paradise" },
  { image: "game-09.png", name: "bunny bonanza spin" },
  { image: "game-10.png", name: "crocodile slayer" },
  { image: "game-11.png", name: "dragon slayer" },
  { image: "game-12.png", name: "dragon dynasty" },
  { image: "game-13.png", name: "plus dragon slayer" },
  { image: "game-14.png", name: "eagle strike" },
  { image: "game-15.png", name: "plus fire kirin" },
  { image: "game-16.png", name: "fish jackpot party" },
  { image: "game-17.png", name: "ocean king frog frenzy" },
  { image: "game-18.png", name: "plus golden ship" },
  { image: "game-19.png", name: "hexa keno" },
  { image: "game-20.png", name: "dragon conqueror" },
  { image: "game-21.png", name: "five times play deluxe" },
  { image: "game-22.png", name: "forest aviators" },
  { image: "game-23.png", name: "gold rush frenzy" },
  { image: "game-24.png", name: "hot buffalo" },
  { image: "game-25.png", name: "jokers mystery reels" },
  { image: "game-26.png", name: "lady diamond" },
  { image: "game-27.png", name: "raging kingdom" },
  { image: "game-28.png", name: "skeleton donee" },
  { image: "game-29.png", name: "St Patrick Fortunes" },
  { image: "game-30.png", name: "king crab" },
  { image: "game-31.png", name: "king of olympus" },
  { image: "game-32.png", name: "legends of circus" },
  { image: "game-33.png", name: "lion chief" },
  { image: "game-34.png", name: "lucky duck" },
  { image: "game-35.png", name: "lucky easter" },
  { image: "game-36.png", name: "merry christmas " },
  { image: "game-37.png", name: "plus milky kirin" },
  { image: "game-38.png", name: "china town" },
  { image: "game-39.png", name: "kingdom of atlantis" },
  { image: "game-40.png", name: "milky moolah" },
  { image: "game-41.png", name: "ocean king monster" },
  { image: "game-42.png", name: "mysterious kingdom" },
  { image: "game-43.png", name: "black jack 21" },
  { image: "game-44.png", name: "life of luxury" },
  { image: "game-45.png", name: "panda vs alien" },
  { image: "game-46.png", name: "santa riches" },
  { image: "game-47.png", name: "tripple sevens" },
  { image: "game-48.png", name: "ocean king crab avengers" },
  { image: "game-49.png", name: "penguin riches" },
  { image: "game-50.png", name: "piggy gold" },
  { image: "game-51.png", name: "pirates gold" },
  { image: "game-52.png", name: "poto gold" },
  { image: "game-53.png", name: "race the horse" },
  { image: "game-54.png", name: "roman riches" },
  { image: "game-55.png", name: "super red hot 7's" },
  { image: "game-56.png", name: "super ball keno" },
  { image: "game-57.png", name: "super bingo" },
  { image: "game-58.png", name: "super joker" },
  { image: "game-59.png", name: "templar realm" },
  { image: "game-60.png", name: "vegas hits" },
  { image: "game-61.png", name: "volcamon strike" },
  { image: "game-62.png", name: "wild fruit party" },
];

export { cardData };
