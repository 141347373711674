import React from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import MilkyWay from "./components/MilkyWay";
import Cards from "./components/Cards";
import DownloadMilkyWay from "./components/DownloadMilkyWay";
import CopyRight from "./components/CopyRight";
import 'bootstrap/dist/css/bootstrap.min.css';
import "reactstrap/lib";
import { RouterProvider } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

const router = createBrowserRouter([
  {
    path : '/',
    element : <Layout />,
    children : [
      {
        index : true,
        element : <Home />
      },
      {
        path : '*',
        element : <NotFound />
      }
    ]
  }
])

function App() {
  return (
    <div className="component-wrapper">
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
