import React from "react";
import "./MilkyWay.css";

const MilkyWay = () => {
    return (
        <div className="background-image">
            <div className="hero-wrapper">
                <h1 className="signup-txt" style={{width : '60%'}}>Download MilkyWay777 for Free & Play Exciting Online Casino Games!</h1>

            </div>
            <div className="play-button-warpper">
                <button
                    className="play-button"
                    onClick={() => window.open('https://www.facebook.com/profile.php?id=61564151433682', '_blank')}
                >
                    <b className="play-now">Join Now</b>
                </button>
            </div>
            <div className="now-play">NOW PLAY DIRECTLY WITH US!</div>

            <div className="banner-container-three">
                <div className="hero-title">
                    <div className="looking-for-an">
                        Looking for an exciting gaming experience? Look no further than
                        Milkyway's fish game and reels app! With free play, bonuses, community
                        prizes, and more, you'll never run out of ways win big. And with our
                        app, you can play any device, anytime, anywhere. Join our family today
                        and experience the thrill of the game!
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MilkyWay;
