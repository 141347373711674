import React from 'react'
import MilkyWay from '../components/MilkyWay'
import DownloadMilkyWay from '../components/DownloadMilkyWay'
import Cards from '../components/Cards'
import Description from '../components/Description'
import ExistingFeatures from '../components/ExistingFeatures'

const Home = () => {
    return (
        <React.Fragment>
            <MilkyWay />
            <Description />
            <ExistingFeatures />
            <Cards />
            <DownloadMilkyWay />
        </React.Fragment>
    )
}

export default Home
