import React from "react";
import { Container } from "reactstrap"
import "./ExistingFeatures.css";

const ExistingFeatures = () => {
    return (
        <React.Fragment>
            <div className="bg-image">
                <div className="features-container">
                    <h2 className="features-heading">Get Exclusive Features with the MilkyWay777 App</h2>
                    <p className="features-description">Milkyway provides a 200% bonus on the first deposit. Here are the key features of the Milky Way app:</p>
                    <ul className="feature-list">
                        <li className="feature-item">Free-to-Play Games</li>
                        <li className="feature-item">Large Game Selection with Mobile Compatibility</li>
                        <li className="feature-item">Rewards and Bonuses</li>
                        <li className="feature-item">Community and Social Features</li>
                        <li className="feature-item">Secure Platform</li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
};

export default ExistingFeatures;
